























import { defineComponent } from '@vue/composition-api';
import HeatingSystemListQuery from '@/hsc-api/queries/HeatingSystemCollectionWithPaginationQuery.gql';
import { APOLLO_CLIENT } from '@/features/core/container/model';
import { HeatingSystemCollectionWithPaginationQuery } from '@/hsc-api/queries/__generated__/HeatingSystemCollectionWithPaginationQuery';
import { HeatingSystemMetadataResponse } from '@/features/app-heating-system/views/tree-node/heating-systems-list/HeatingSystemsMetadataModal.vue';

export default defineComponent({
  data() {
    return {
      heatingSystems: null as HeatingSystemCollectionWithPaginationQuery | null,
      heatingSystemsMetadata: [] as HeatingSystemMetadataResponse[] | null,
      incompleteMetatadata: [] as HeatingSystemCollectionWithPaginationQuery['heatingSystemsList']['items'],
    };
  },
  mounted() {
    Promise.all([this.fetchHeatingSystemsMetadata(), this.fetchHeatingSystems()]).then(() => {
      this.heatingSystems?.heatingSystemsList.items.forEach((hs) => {
        if (this.heatingSystemsMetadata?.find((metadata) => metadata.heatingSystemId !== hs.id)) {
          this.incompleteMetatadata.push(hs);
        }
      });
    });
  },
  methods: {
    async fetchHeatingSystemsMetadata(): Promise<void> {
      try {
        const res = await this.$store.dispatch('getAllHeatingSystemMetadata');
        const data = await res.json();
        this.heatingSystemsMetadata = data;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
    async fetchHeatingSystems(): Promise<void> {
      try {
        const { data } = await this.$apollo.query<HeatingSystemCollectionWithPaginationQuery>({
          query: HeatingSystemListQuery,
          client: APOLLO_CLIENT.HEATING_SYSTEM_COLLECTOR_CLIENT,
          variables: {
            skip: 0,
            take: 50,
          },
          fetchPolicy: 'no-cache',
        });
        this.heatingSystems = data;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
  },
});
